export class User {
    firstName: string;
    lastName: string;
    password: string;
    key: string;
    photo: File;
    email: string;
    name: string;
    phoneNumber: string;
    photoId: string;
    photoUrl: string;
    isAdmin: boolean;
    language: Language;

    // helper
    image: any;
    confirmPassword: string;
}

export enum Language {
    'de' = 0,
    'en' = 1,
}
